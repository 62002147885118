import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    type: String
  }

  timeout = null

  connect() {
    if (this.#shouldAutoDismiss()) {
      this.scheduleDismissal()
    }
  }

  disconnect() {
    clearTimeout(this.timeout)
  }

  close() {
    this.#dismiss()
    clearTimeout(this.timeout)
  }

  scheduleDismissal() {
    this.timeout = setTimeout(() => {
      this.#dismiss()
    }, 6000)
  }

  #dismiss () {
    this.element.classList.remove("tw-animate-fade-in")
    this.element.classList.add("tw-animate-fade-out")
    this.element.addEventListener("animationend", () => {
      this.element.remove()
    })
  }

  #shouldAutoDismiss() {
    return this.typeValue != "persistent_notice"
  }
}
